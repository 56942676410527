import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { AppService } from '../../../Service/app.service';
import { FormGroup, FormControl } from '@angular/forms';
import { DeliverypointsService } from '../../../Service/deliverypoints.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { PartnerService } from '../../../Service/partner.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import *as Papa from 'papaparse';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import * as momenttimezone from 'moment-timezone';
import { LangtransService } from 'src/app/Service/langtrans.service';
import { HttpClient } from '@angular/common/http';
import { utcFormatter } from '../../timeFormatter';
import { SpinnerService } from 'src/app/Service/spinner.service';
import { UserService } from 'src/app/Service/user.service';
import { AuthorizationService } from 'src/app/Service/authorization.service';
import {Config} from 'datatables.net';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-lockerlist',
  templateUrl: './lockerlist.component.html',
  styleUrls: ['./lockerlist.component.scss']
})
export class LockerlistComponent implements OnDestroy, OnInit, AfterViewInit {
  resultsingle: any;
  showSpinner = false;
  showDownload = true;
  result: any = [];
  commissioned: any;
  lockerdetails: any;
  notCommissioned: any;
  online: any;
  offline: any;
  dashBoard: any;
  dashBoardError: boolean;
  lockername: string;
  street: string;
  lockerprovider: string;
  lockertype: string;
  postalCode: string;
  acCode: string;
  active: string;
  lockerid: string;
  public filterForm;
  public lockerDetailsForm;
  partnerNamedropdownList = [];
  partnerList: any;
  partnerNamedropdownSettings: IDropdownSettings;
  acCodedropdownSettings: IDropdownSettings;
  acCodedropdownList = [];
  acCodeselectedItems= [];
  partnerNameselectedItems = [];
  dtOptions: Config ={} ;
  // thus we ensure the data is fetched before rendering
  dtTrigger: Subject<any> = new Subject<any>();
   @ViewChild(DataTableDirective, { static: false })
     dtElement: DataTableDirective;
   results: any = [];
   formattedTime: string;
   timeDifference = 30;
   isAlert: boolean;
   timeDiffmin: number;
   showUnauthorized = false;
  @ViewChild('myDiv', { static: true }) myDiv: ElementRef;
  lockerFetchfail: any;
  translateData: any;
  @ViewChild('holidayDateInput') holidayDateInput: ElementRef;
  headerVariables: any[];
  hasErrorLoading = false;
  partnerDropdown: boolean;
  nodataText: any;
  userInfo: any;
  resultNew: any;
  constructor(
    private readonly _http: HttpClient,
    private readonly _langtransService: LangtransService,
    private readonly _appService: AppService,
    private readonly _deliverypointsService: DeliverypointsService,
    public _router: Router,
    private readonly _toastr: ToastrService,
    private readonly _PartnerService: PartnerService,
    private readonly _datePipe: DatePipe,
    private readonly _renderer: Renderer2,
    private readonly _spinner: SpinnerService,
    private readonly _userService: UserService,
    private readonly _translate: TranslateService,
    private readonly _authorizationService: AuthorizationService
  ) {
  }
  async ngOnInit() {
    $('.details').click(function () {
      $(this).find('i').toggleClass('fa fa-chevron-down fa fa-chevron-up');
    });
    this._appService.languagetoggle$.subscribe((resdata: any) => {
      this.translateData = resdata;
      this.headerVariables = [];
      this._http.get(`/assets/i18n/${resdata}.json`).subscribe((data: any) => {
        this.nodataText = data.nodataText;
        this.headerVariables.push(data.AcCode, data.LockerID, data.Name,
          data.Location, data.LockerType, data.Online, data.OperationalStatus,
          data.DeliveryPointId, data.IpAddress, data.Provider, 
          data.Techology, data.LastPingTime,
          data.totalDoors, data.acceptSoftReservations, data.windBrackets, 
          data.position, data.salesSegment,
          data.contractName, data.contractHostName, data.numberOfUnits, 
          data.salesKeyAccount, data.operationsFieldSupport,
          data.locationDeliveryRoute, data.mailOffice, data.logisticsPlatform, 
          data.mailCentre, data.windDirection, data.HardwareType
        );
      });
    });
    this.filterForm = new FormGroup({
      lockername: new FormControl('', []),
      street: new FormControl('', []),
      postalCode: new FormControl('', []),
      acCode: new FormControl('', []),
      lockerid: new FormControl('', []),
      lockerprovider: new FormControl('', []),
      hardwareProvider: new FormControl('', []),      
      lockertype: new FormControl('', []),
      active: new FormControl('', []),
      online: new FormControl('', []),
      commisioned: new FormControl('', []),
      technology: new FormControl('', []),
      partnerId: new FormControl('', []),
      holidayDate: new FormControl('', []),
      acceptSoftReservations: new FormControl('', []),
    });
    this.acCodedropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'type',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 1,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: this.nodataText
    };

    this.fetchLockerAcCode();
    this.fetchRecords();

    this._authorizationService.currentDdsUser.subscribe(user => {
      this.userInfo = user;
      if (this.userInfo?.partner.id === 1) {
        this.fetchPartners();
        this.partnerDropdown = true;
      } else {
        this.partnerDropdown = false;
      }
    });
   
    this.fetchDashBoard();
  }

  ngAfterViewInit() {
    const divElement = this.myDiv.nativeElement;
    const divstyle = window.getComputedStyle(divElement);
    const display = divstyle?.display;
    if (display === 'none') {
      this.showUnauthorized = true;
    }
  }

  langaugeFetch(lang, key) {
    this._langtransService.TranslateData(lang, key).subscribe(
      res => {
        this._toastr.error(res);
      }
    );
  }

  fetchDashBoard() {
    this._spinner.show();
    this._deliverypointsService.fetchDashBoard().subscribe({
      next:res => {
        this.dashBoard = res;
        this.dashBoardError = false;
        this.commissioned = this.dashBoard?.commisioned?.completed;
        this.notCommissioned = this.dashBoard?.commisioned?.total;
        this.online = this.dashBoard?.online;
        this.offline = this.dashBoard?.offline;
        this._spinner.hide();
      },
      error:err => {
        this.dashBoardError = true;
        console.error(err);
        this._spinner.hide();
      },
      complete:()=>{}
  });
  }

  loading(on: boolean) {
    const state = on === false ? false : true;
    if (state === true) {
      this._spinner.show();
    } else {
      this._spinner.hide();
    }
    return Promise.resolve(state);
  }

  fetchLockerAcCode(){
    this._spinner.show();
    this._deliverypointsService.fetchAcCode().then(res=>{
      const acCodes= res.map(obj=>obj.acCode);
      this.acCodedropdownList = acCodes.filter((obj1, i, arr) => 
        arr.findIndex(obj2 => (obj2 === obj1)) === i
      );
      this._spinner.hide();
    });
  }
  fetchRecords(){
    let self = this;
    let lastPage=0;
    this.loading(true);
    this.dtOptions = {
      retrieve: true,
      destroy: true,
      pagingType: 'full_numbers',
      pageLength: 50,
      displayStart: lastPage,
      searching: false,
      serverSide: true,
      order: [[1, 'desc']],
      ordering: false,
      ajax: (dataTablesParameters: any, callback) => {
        this._deliverypointsService.fetchManyNew(this.validate(), dataTablesParameters.length, dataTablesParameters.start).then((res) => {
          const header = res.headers.get('X-Total-Count');
          this.loading(false);
          if (res.body.length > 0) {
            this.resultNew = res.body.map(i => {
              if (i?.connectivity?.updatedAt) {
                const dateval = i.connectivity.updatedAt;
                i.connectivity.updatedAt = dateval.split('Z').join('');
              }
              i.connectivity.online = !!i.connectivity.online;
              return i;
            });
            this.convertupdatedDate(this.resultNew);
            this.result = this.resultNew;
          }
          else {
            this.result = [];
            this.resultNew = [];
          }
          callback({
            recordsTotal: header,
            recordsFiltered: header,
            data: []
          });
        }).catch((error) => {
          this.result = [];
          this.resultNew = [];
          this.loading(false);
          callback({
            recordsTotal: 0,
            recordsFiltered: 0,
            data: []
          });
        });
      }
    };
  }
  validate() {
    let isActive: boolean | undefined
    if (this.filterForm.value.active === 'true') {
      isActive = true
    } else if (this.filterForm.value.active === 'false') {
      isActive = false
    } else {
      isActive = undefined
    }
    const partnerName = [];
    const acCode = this.filterForm.value.acCode;
    const partnerNameForm = this.filterForm.value.partnerId ?
      this.filterForm.value.partnerId : [];
    const processedRequest = {};
    if (partnerNameForm.length !== 0) {
      for (const element of partnerNameForm) {
        partnerName.push(element.id);
      }
    }
    return {
      name: this.filterForm.value.lockername || undefined,
      street: this.filterForm.value.street || undefined,
      provider: this.filterForm.value.lockerprovider || undefined,
      hardwareProvider: this.filterForm.value.hardwareProvider || undefined,
      type: this.filterForm.value.lockertype || undefined,
      active: isActive,
      acCode: (acCode.join(',')) || undefined,
      postalCode: this.filterForm.value.postalCode || undefined,
      lockerId: this.filterForm.value.lockerid || undefined,
      online: this.filterForm.value.online || undefined,
      commisioned: this.filterForm.value.commisioned || undefined,
      technology: this.filterForm.value.technology || undefined,
      partnerId: (partnerName.join(',')) || undefined,
      holidayDate: this.filterForm.value.holidayDate ?
        this._datePipe.transform(this.filterForm.value.holidayDate,
          'yyyy-MM-dd') : undefined,
      acceptSoftReservations:
        this.filterForm.value.acceptSoftReservations || undefined,
    };


  }
  onSubmit() {
    this.result = [];
    this.hasErrorLoading = false;
    this.rerender();
    this.fetchRecords();
  }
  
  exportToCSV() {
    this.loading(true)
      .then(() => this._deliverypointsService.downloadLockerDetails(
        this.validate()))
      .then((res: any) => {
        const csvData = this.formatDataForCSV(res);
        const Today = this._datePipe.transform(new Date(), 'dd-MM-yyyy');
        const downtimeReport = `Delivery_Points_${Today}.csv`;
        this.downloadCSV(csvData, downtimeReport);
      })
      .catch(err => {
        this.langaugeFetch(this.translateData, 'ErrordownloadingthefileFailed');
        console.error(err);
      })
      .finally(() => this.loading(false));
  }
  formatDataForCSV(data: any[]): string {
    let onlineStatus;
    const flattenData = data.map((item) => {
      if (!item.connectivity.online || this.isAlert) {
        onlineStatus = 'False';
      }
      if (item.connectivity.online && !this.isAlert) {
        onlineStatus = 'True';
      }
      return [
        item.acCode,
        item.lockerId,
        item.lockerName,
        `${item.address.street  },${  item.address.streetNumber  
        },${  item.address.postalCode  },${  item.address.municipality  
        },${  item.address.countryCode}`,
        item.type,
        onlineStatus,
        item.operations.lockerOperationActive,
        item.id,
        item.connectivity.ipAddress,
        item.provider,
        item.connectivity.technology,
        this.timeFormatter(item.connectivity.updatedAt) || '',
        item.totalDoors || '',
        item.acceptSoftReservations || '',
        item.windBrackets || '',
        item.position || '',
        item.salesSegment || '',
        item.contractName || '',
        item.contractHostName || '',
        item.numberOfUnits || '',
        item.salesKeyAccount || '',
        item.operationsFieldSupport || '',
        item.locationDeliveryRoute || '',
        item.mailOffice || '',
        item.logisticsPlatform || '',
        item.mailCentre || '',
        item.windDirection || '',
        item.hardwareType || ''
      ];
    });
    const headers = [
      this.headerVariables[0], this.headerVariables[1], 
      this.headerVariables[2], this.headerVariables[3], this.headerVariables[4],
      this.headerVariables[5], this.headerVariables[6], 
      this.headerVariables[7], this.headerVariables[8], this.headerVariables[9],
      this.headerVariables[10], this.headerVariables[11], 
      this.headerVariables[12], this.headerVariables[13], 
      this.headerVariables[14],
      this.headerVariables[15], this.headerVariables[16], 
      this.headerVariables[17], this.headerVariables[18], 
      this.headerVariables[19], this.headerVariables[20], 
      this.headerVariables[21], this.headerVariables[22], 
      this.headerVariables[23], this.headerVariables[24],
      this.headerVariables[25], this.headerVariables[26], 
      this.headerVariables[27]
    ];
    return Papa.unparse({ fields: headers, data: flattenData }, 
      { quotes: true });
  }

  downloadCSV(data: string, filename: string) {
    const encodedCsvData = encodeURIComponent(data);
    const csvUrl = 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodedCsvData;
    const link = document.createElement('a');
    link.href = csvUrl;
    link.setAttribute('download', filename);
    link.style.visibility = 'none';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  getId(id: any) {
    this._router.navigate([`/deliverypoints/${id}/details`]);
  }

  onReset() {
    const currentUrl = this._router.url;
    this._router.routeReuseStrategy.shouldReuseRoute = () => false;
    this._router.onSameUrlNavigation = 'reload';
    this._router.navigate([currentUrl]);
  }

  rerender(): void {
    this.dtElement.dtInstance?.then(dtInstance => {
      dtInstance.ajax.reload();
      dtInstance.on('draw.dt', () => {
        const dataTableEmpty = '.dataTables_empty'
        if ($(dataTableEmpty).length > 0) {
          if (this.hasErrorLoading) {
            $(dataTableEmpty).text('Error in loading data');
            this.showDownload = false;
          } else if (this.result?.length === 0) {
            $(dataTableEmpty).text('No records found!');
            this.showDownload = false;
          }
        } else {
          this.showDownload=true;
        }
      });
      this.dtTrigger.next(null);
    });
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  fetchPartners() {
    this._spinner.show();
    this._PartnerService.fetchPartnersList().subscribe({
      next:result => {
        this._spinner.hide();
        this.partnerList = result;
        const partnerNameList = [];
        for (let i = 0; i < this.partnerList.length; i++) {
          if (this.partnerList[i].active === true) {

            partnerNameList[i] = { id: this.partnerList[i].id, 
              name: `${this.partnerList[i].name}` };
          }
        }
        this.partnerNamedropdownList = partnerNameList;
        this.partnerNamedropdownSettings = {
          singleSelection: false,
          idField: 'id',
          textField: 'name',
          selectAllText: 'Select All',
          unSelectAllText: 'UnSelect All',
          itemsShowLimit: 1,
          allowSearchFilter: true
        };
      },error: err => {
        this._spinner.hide();
        console.error(err);
      },
      complete:()=>{}
      });
  }

  convertupdatedDate(res: any) {
    for (const element of res) {
      if (element.connectivity.updated != null) {
        const timestamp = 
        (element.connectivity.updatedAt).replace('T', ' ').replace('Z', '');

        const currentTime = new Date();

        const createdDate = 
        momenttimezone(currentTime).tz('Europe/Berlin').format(
          'YYYY-MM-DD HH:mm:ss');

        const startDown = moment(timestamp);
        const endDown = moment(createdDate);

        this.timeDiffmin = Math.abs(endDown.diff(startDown, 'minutes'));

        if (this.timeDiffmin < this.timeDifference) { 
          this.isAlert = false; } 
        else { 
          this.isAlert = true; }
      }
    }
  }
  openDatePicker(v) {
    if (v === 'holidayDateInput') {
      this._renderer.selectRootElement(
        this.holidayDateInput.nativeElement).click();
    }
  }
  timeFormatter(date) {
    const formattedDateTime = utcFormatter(date);
    if (!formattedDateTime) { return null; }
    return this._datePipe.transform(formattedDateTime, 'dd-MM-YYYY HH:mm:ss');
  }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { AuthorizationService } from 'src/app/Service/authorization.service';
import { SpinnerService } from 'src/app/Service/spinner.service';
import { UserService } from 'src/app/Service/user.service';
import { ToastrService } from 'ngx-toastr';
import { LangtransService } from 'src/app/Service/langtrans.service';
import { AppService } from 'src/app/Service/app.service';

@Component({
  selector: 'app-usergroupaddedit',
  templateUrl: './usergroupaddedit.component.html'
})
export class UsergroupaddeditComponent implements OnInit {
  title: any = '';
  groupId: any='';
  checklist: any;
  titleKeys: any;
  groupHeading: any;
  selectAll: any = false;
  langKey: any;
  constructor(
    private readonly _user: UserService,
    public _router: Router,
    public _activatedRoute: ActivatedRoute,
    private readonly _spinner: SpinnerService,
    public _authorizationService: AuthorizationService,
    private readonly _toastr: ToastrService,
    private readonly _langtransService: LangtransService,
    private readonly _appService: AppService,
  ) { }
  ngOnInit(): void {
    this._appService.languagetoggle$.subscribe((data: any) => {
      this.langKey = data;
    });
    const currentAction = this._activatedRoute.snapshot.paramMap.get('action');
    if (currentAction === 'edit') {
      const group = this._activatedRoute.snapshot.paramMap.get('group');
      this._user.getUsergroup().subscribe({
        next:res => {
        const groupData: any = res;
        const filteredVal = groupData.filter((element) => {
         return element.userGroups.title===group
        });

        const defaultChecklist = this._user.checklistData;

        const groupPermissions = filteredVal[0].userGroups.checklistData.map((groups:any) => {
          const key = Object.keys(groups)[0];
          const defaultGroup = defaultChecklist.find((defaultGroups) => Object.keys(defaultGroups)[0] === key);
          // Merge the default permissions with the user group permissions
          groups[key] = Object.assign({}, defaultGroup[key], groups[key]);
          return groups;
        });

        this.checklist = groupPermissions;

        this.title = filteredVal[0].userGroups.title;
        this.groupId = filteredVal[0].id;
        this.setTitleKeys();
      },
      error:err => {
        console.error('THE ERROR IS', err);
      },
      complete:()=>{}
    });
    }
    else {
      this.checklist = this._user.checklistData;
      this.setTitleKeys();
    }
  }
  setTitleKeys() {
    this.titleKeys =this.checklist.flatMap((obj)=>Object.keys(obj)[0])
    this.groupHeading = {};
    for (const element of this.titleKeys) {
      this.groupHeading[element] = false;
    }

    this.checklist.forEach((object, index) => {
      let falseCount = 0;
      for (const key in object[this.titleKeys[index]]) {
        if (object[this.titleKeys[index]][key] === false) {
          falseCount = falseCount + 1;
        }

      }
      if (falseCount === 0) {
        this.groupHeading[this.titleKeys[index]] = true;
      }

    });

    this.checkForSelectAll();


  }

  checkForSelectAll() {
    let checkFalse = 0;
    for (const key in this.groupHeading) {
      if (this.groupHeading[key] === false) {
        checkFalse = checkFalse + 1;
      }
    }
    if (checkFalse === 0) {
      this.selectAll = true;
    }
  }
  innerGroup(value: any, index: any, event: any) {
    if (!event.target.checked) {
      this.groupHeading[value] = false;
      this.selectAll = false;
    }
    else {
      let falseCount = 0;
      for (const key in this.checklist[index][value]) {
        if (this.checklist[index][value][key] === false) {
          falseCount = falseCount + 1;
        }
      }
      if (falseCount === 0) {
        this.groupHeading[value] = true;
      }
      this.checkForSelectAll();
    }
  }
  selectGroup(value: any, index: any, event: any) {
    if (event.target.checked) {
      for (const key in this.checklist[index][value]) {
        this.checklist[index][value][key] = true;
      }
      this.checkForSelectAll();
    }
    else {
      this.selectAll = false;
      for (const key in this.checklist[index][value]) {
        this.checklist[index][value][key] = false;
      }
    }
  }
  getObjectKeys(item: any, index: any): any[] {
    return Object.keys(item[this.titleKeys[index]]);
  }
  selectall(event: any) {
    if (event.target.checked) {
      this.checklist.forEach((obj, index) => {
        for (const key in obj[this.titleKeys[index]]) {
          obj[this.titleKeys[index]][key] = true;
        }
      });
      for (const j in this.groupHeading) {
        this.groupHeading[j] = true;
      }
    }
    else {
      this.checklist.forEach((obj, index) => {
        for (const key in obj[this.titleKeys[index]]) {
          obj[this.titleKeys[index]][key] = false;
        }
      });
      for (const j in this.groupHeading) {
        this.groupHeading[j] = false;
      }
    }
  }
  saveDetails() {
    let falseKey = true;
    let trueCount = 0;
    this.checklist?.forEach((object, index) => {

      for (const key in object[this.titleKeys[index]]) {
        if (object[this.titleKeys[index]][key] === true) {
          trueCount = trueCount + 1;
        }
      }

    });
    if (trueCount === 0) {
      falseKey = false;
    }
    const body = {
      title: this.title || null,
      groupId: this.groupId || null,
      checklistData: this.checklist,
      allPrivilegesFalse: falseKey
    };
    this._spinner.show();
    if(body.title===null){
      this._spinner.hide();
      this.langaugeFetch(this.langKey, 'titleRequired', 'error');
    }else {
    this._user.addUsergroup(body).subscribe({
      next:res => {
        this.setGroups();
        this._spinner.hide();
        // this.router.navigate(['user/usergroup'])
      },
     error: err => {
        this._spinner.hide();
        console.error('THE ERROR IS', err);

      },
      complete:()=>{}
  });
  }
}
  setGroups() {
    let userEmail: any;

    let userData: any;
    this._authorizationService.currentDdsUser.subscribe(user => {
      userData = user;
   

      if (userData.email == null || userData.email === undefined) {
        userEmail = userData.mail || userData.Email;
      }
      else {
        userEmail = userData.email; 
      }
      const filterVal= {
        email: userEmail.toLowerCase(),
        name: undefined,
        groups: undefined,
      };
      this._spinner.show();
      forkJoin([this._user.fetchMany(filterVal), this._user.getUsergroup()]).subscribe({
        next:(res: any[]) => {
        this._spinner.hide();
        this._router.navigate(['user/usergroup']); 
      },
      error:err => {
        console.error(err);
        this._spinner.hide();
      },
      complete:()=>{}
    });

    });
  }
  cancel = () => {
    this._router.navigate(['/user/usergroup']);
  };
  langaugeFetch(lang, key, status) {
    this._langtransService.TranslateData(lang, key).subscribe(
      res => {
        if (status === 'success') {
          this._toastr.success(res);
        }
        if (status === 'error') {
          this._toastr.error(res);
        }
      }
    );
  }
}

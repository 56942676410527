import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PartnerService } from '../../../Service/partner.service';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { LangtransService } from 'src/app/Service/langtrans.service';
import { AppService } from '../../../Service/app.service';
import { DatePipe } from '@angular/common';
import { FormGroup, FormBuilder } from '@angular/forms';
import * as Papa from 'papaparse';
import { HttpClient } from '@angular/common/http';
import { SpinnerService } from 'src/app/Service/spinner.service';
import { Config } from 'datatables.net';

@Component({
  selector: 'app-partner-list',
  templateUrl: './partner-list.component.html',
  styleUrls: ['./partner-list.component.scss'],
})
export class PartnerListComponent implements OnDestroy, OnInit, AfterViewInit {
  partnersList: any;
  dtOptions: Config ={};;
  // thus we ensure the data is fetched before rendering
  dtTrigger: Subject<any> = new Subject<any>();
  @ViewChild(DataTableDirective, { static: false })
    dtElement: DataTableDirective;
  lockerFetchfail: any;
  translateData: any;
  filterForm: FormGroup;
  deleteId: any;
  partnerName: any;
  bussinessPartnerSettings: any;
  submitted: boolean;
  selectedPartnerIds = [];
  @ViewChild('partnerEdit', { static: false }) partnerEdit: ElementRef;
  ids = { 'partnerId': [] };
  FilterPartnersList: any;
  AcCode: any;
  LockerName: any;
  PartnerName: any;
  LockerPostalCode: any;
  LockerAddress: any;
  LockerMunicipality: any;
  LockerType: any;
  LockerStatus: any;
  showEdit = false;

  constructor(
    private readonly _langtransService: LangtransService,
    private readonly _appService: AppService,
    public _router: Router,
    private readonly _toastr: ToastrService,
    private readonly _partnerService: PartnerService,
    private readonly _datePipe: DatePipe,
    private readonly _formBuilder: FormBuilder,
    private readonly _http: HttpClient,
    private readonly _spinner: SpinnerService
  ) {
    this.filterForm = this._formBuilder.group({
      partnerName: ['']
    });
  }

  ngOnInit(): void {
    this._appService.languagetoggle$.subscribe((lang: any) => {
      this.translateData = lang;
      this._http.get(`/assets/i18n/${lang}.json`).subscribe({next:(data: any) => {
        this.AcCode = data.AcCode;
        this.PartnerName = data.PartnerName;
        this.LockerName = data.LockerName;
        this.LockerAddress = data.LockerAddress;
        this.LockerType = data.LockerType;
        this.LockerMunicipality = data.LockerMunicipality;
        this.LockerStatus = data.LockerStatus;
        this.LockerPostalCode = data.LockerPostalCode;
      },
      error:err=>{},
      complete:()=>{}
      
    });
    });
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 50,
      destroy: true,
      searching: false,
    };
    this.getPartnerDropdown();
    this.getPartnersList(this.ids);
    this.bussinessPartnerSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      itemsShowLimit: 1,
      allowSearchFilter: true
    };
  }

  ngAfterViewInit() {
    const partneredit = this.partnerEdit.nativeElement;
    const partnerstyle = window.getComputedStyle(partneredit);
    const partnerDisplay = partnerstyle?.display;
    if (partnerDisplay === 'none') {
      this.showEdit = true;
    }
    else {
      this.showEdit = false;
    }
  }

  downloadAll() {
    this._spinner.show();
    let PartnerReport;
    this._partnerService.downloadAll(this.ids)
      .subscribe({next:(receivedFile: any) => {
        const csvData = this.formatDataForCSV(receivedFile);
        const Today = this._datePipe.transform(new Date(), 'dd-MM-yyyy');
        if (this.ids && this.ids.partnerId.length === 1) {
          const str = receivedFile[0].name;
          const partnerNamestr = str.replace(/ /g, '_');
          PartnerReport = `partner_overview_${partnerNamestr}_${Today}`;
        } else {
          PartnerReport = `partner_overview_all_${Today}`;
        }
        this.downloadCSV(csvData, PartnerReport);
      }, error:(error) => {
        console.error(error);
        this.langaugeFetch(this.translateData, 'Errordownloadingthefile');
      },
      complete:()=>{}
      });
  }

  formatDataForCSV(data: any[]): string {
    const flattenData = data.map((item) => {
      return [
        item.name,
        item.lockerName,
        item.lockerAcCode,
        item.lockerPostalCode,
        item.lockerStreetName,
        item.lockerMunicipality,
        item.lockerType,
        item.lockerOperationActive
      ];
    });
    const headers = [this.PartnerName, this.LockerName, 
      this.AcCode, this.LockerPostalCode, this.LockerAddress, 
      this.LockerMunicipality, this.LockerType, this.LockerStatus];
    return Papa.unparse({ fields: headers, 
      data: flattenData }, { quotes: true });
  }


  downloadCSV(data: string, filename: string) {
    const blob = new Blob([data], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    a.click();
    window.URL.revokeObjectURL(url);
    this._spinner.hide();
  }

  langaugeFetch(lang, key) {
    this._langtransService.TranslateData(lang, key).subscribe(
      res => {
        this._toastr.error(res);
      }
    );
  }

  getPartnerDropdown() {
    this._partnerService.fetchPartnersList().subscribe({
     next: res => {
        this.FilterPartnersList = res;
      },
      error:(err) => {
        console.error(err);
      },
      complete:()=>{}
  });
  }

  getPartnersList(partnerIds) {
    this.partnersList = [];
    this._spinner.show();
    this.partnersList = [];
    this._partnerService.fetchAndFilterPartnersList(partnerIds).subscribe({
      next:res => {
        this.partnersList = res;
        if (this.partnersList.length === 0) {
          this.langaugeFetch(this.translateData, 'Failedtofetchpartnerlist');
        }
        this._spinner.hide();
        this.dtTrigger.next(null);
      },
      error:(err) => {
        this._spinner.hide();
        this.langaugeFetch(this.translateData, 'Failedtofetchpartnerlist');
        console.error(err);
      },
      complete:()=>{}
  });
  }


  get formValidators() {
    return this.filterForm.controls;
  }

  rerender(): void {
    this.dtElement.dtInstance.then(dtInstance => {
      dtInstance.destroy();
    });
  }

  onSubmit() {
    this.submitted = true;
    this.selectedPartnerIds = [];
    if (this.filterForm.valid) {
      const selectedPartners = this.filterForm.controls.partnerName.value;
      for (const element of selectedPartners) {
        this.selectedPartnerIds.push(element.id);
      }
      this.ids = { 'partnerId': this.selectedPartnerIds };
      const partnerIds = { 'partnerId': this.selectedPartnerIds };
      this.getPartnersList(partnerIds);
      this.rerender();
    }
  }

  onReset() {
    const currentUrl = this._router.url;
    this._router.routeReuseStrategy.shouldReuseRoute = () => false;
    this._router.onSameUrlNavigation = 'reload';
    this._router.navigate([currentUrl]);
  }

  onEdit(partner) {
    this._router.navigate(['/partners/', partner.id]);
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }
}
